import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import {
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    LoaderTypes,
    SelectInput,
    TextInput,
} from "~/core";
import { getTheUserGuid } from "~/login";
import { FileImportAPI, RecAPI } from "@ai360/core";

import { messages } from "../../../i18n-messages";

import "./export-controller-file-modal.css";
import { IGenericOption, IOption } from "~/core/components/select-input/model";
import { cloneDeep, reduce, uniqBy } from "lodash";
import {
    REC_TYPE_NAME_EQUATION_APPLICATION,
    RecDetails,
    RecSummary,
} from "~/recs-events/recs/model";
import { IFileNameFormat } from "~/reports/data/interfaces";

interface IProps {
    intl: any;
    isOpen: boolean;
    onAction: (exportRecRequest: FileImportAPI.ExportRecRequest) => void;
    onClose: () => void;
    recGeneralGuidList: string[];
    recSummaries: RecSummary[];
    userGuid: string;
}

interface IRecFilenames {
    customerName: string;
    farmName: string;
    fieldName: string;
    files: INutrientFilename[];
}

interface INutrientFilename {
    nutrient: string | null;
    productMixName: string;
    filename: string | null;
}

interface IControllerFileType {
    guid: string;
    sendThroughOnsite: boolean;
}

interface IState {
    controllerTypeOptions: IOption[];
    createLoadsheetYn: boolean;
    isLoading: boolean;
    mergeControllerFilesYn: boolean;
    multiProductYn: boolean;
    productFormatOptions: IFileNameFormat[];
    selectedControllerFileType: IControllerFileType | null;
    selectedControllerType: string | null;
    selectedExportFormat: string | null;
    validationWarnings: any[];
    controllerFileTypeOptions: IGenericOption<IControllerFileType>[];
    editFilenames: boolean;
    zipFilename: string;
    recNameStart: string | null;
    recNameEnd: string | null;
    recFilenames: IRecFilenames[];
    erroredControls: Set<string>;
    recDetails: RecDetails[];
}

export class ExportControllerFileModal_ extends Component<IProps, IState> {
    readonly invalidFilenameChars = new RegExp(/[!@#$%^&*()[\]{};:,/<>?\\|`~=+']/g);

    constructor(props: IProps) {
        super(props);
        this.state = {
            controllerTypeOptions: [],
            createLoadsheetYn: false,
            isLoading: false,
            mergeControllerFilesYn: false,
            multiProductYn: false,
            productFormatOptions: [],
            selectedControllerFileType: null,
            selectedControllerType: null,
            selectedExportFormat: null,
            validationWarnings: [],
            controllerFileTypeOptions: [],
            editFilenames: false,
            recNameEnd: null,
            recNameStart: null,
            zipFilename: "",
            recFilenames: [],
            erroredControls: new Set<string>(),
            recDetails: [],
        };
    }

    private onAction() {
        const { onAction, recGeneralGuidList } = this.props;
        const {
            createLoadsheetYn,
            mergeControllerFilesYn,
            multiProductYn,
            selectedControllerFileType,
            selectedControllerType,
            selectedExportFormat,
            editFilenames,
            recFilenames,
            zipFilename,
        } = this.state;

        const validationWarnings: string[] = [];
        if (editFilenames) {
            validationWarnings.push(...this.validateFileNaming());
        }

        if (mergeControllerFilesYn || selectedControllerFileType.sendThroughOnsite) {
            validationWarnings.push(...this.validateControllerFileMerge());
        }

        if (validationWarnings.length > 0) {
            this.setState({ validationWarnings });
            return;
        }

        this.setState({ isLoading: true });

        const exportRecRequest = new FileImportAPI.models.ExportRecRequest(recGeneralGuidList);
        exportRecRequest.setProps({
            controllerFileTypeGuid: selectedControllerFileType.guid,
            controllerTypeGuid: selectedControllerType,
            fileNameFormatGuid: selectedExportFormat,
            loadsheetYn: createLoadsheetYn,
            mergeControllerFilesYn,
            multiProductYn,
            ...(editFilenames
                ? {
                      zipFilename,
                      exportFilenames: recFilenames.flatMap(({ files }) =>
                          files.map(
                              ({ filename, productMixName }) =>
                                  // new FileImportAPI.models.ExportRecFilename(
                                  ({ productMixName, filename })
                              //)
                          )
                      ),
                  }
                : {}),
        });
        onAction(exportRecRequest);
    }

    private fetchOptions(nextProps: IProps) {
        const { userGuid, recGeneralGuidList } = nextProps;

        const promises = [];
        promises.push(
            FileImportAPI.getControllerTypeList(userGuid).then((result) =>
                result.map((r) => ({
                    label: r.name,
                    value: r.guid,
                }))
            )
        );

        promises.push(FileImportAPI.getUserExportPreferences(userGuid));

        promises.push(
            FileImportAPI.getFileNameFormatList(userGuid).then((result) => result.fileNameFormats)
        );

        promises.push(
            // no reason to wait for this api call if we won't allow editing filenames
            nextProps.recSummaries.some((x) => x.recType !== REC_TYPE_NAME_EQUATION_APPLICATION)
                ? Promise.resolve([])
                : Promise.all(
                      nextProps.recGeneralGuidList.map((guid) => RecAPI.getRec(userGuid, guid))
                  )
        );

        Promise.all(promises).then(
            ([controllerTypeOptions, prefs, productFormatOptions, recDetails]) => {
                const {
                    controllerExportFormatGuid,
                    controllerFileTypeGuid,
                    controllerTypeGuid,
                    createLoadsheetYn,
                    mergeControllerFilesYn,
                    multiProductYn,
                } = prefs;

                this.setState(
                    {
                        controllerTypeOptions,
                        createLoadsheetYn,
                        mergeControllerFilesYn:
                            recGeneralGuidList.length > 1 && mergeControllerFilesYn,
                        multiProductYn,
                        productFormatOptions,
                        selectedControllerType: controllerTypeGuid,
                        selectedExportFormat: controllerExportFormatGuid,
                        editFilenames: false,
                        recNameStart: null,
                        recNameEnd: null,
                        zipFilename: "",
                        recFilenames: [],
                        recDetails: recDetails as RecDetails[],
                    },
                    () => {
                        this.onChangeControllerType(
                            controllerTypeGuid,
                            this.state.multiProductYn,
                            controllerFileTypeGuid
                        );
                        this.rebuildRecFiles();
                    }
                );
            }
        );
    }

    private rebuildRecFiles() {
        const { recSummaries } = this.props;
        const { recDetails, mergeControllerFilesYn, multiProductYn } = this.state;
        const exportFormat = this.state.productFormatOptions.find(
            (x) => x.guid === this.state.selectedExportFormat
        )?.name;

        let zipFilename: string;
        let recFilenames: IRecFilenames[];

        if (mergeControllerFilesYn && multiProductYn) {
            zipFilename = `Merged_MultiProduct_${recSummaries[0].customerName}`;

            const customerCount = new Set(recSummaries.map((x) => x.customerName)).size;
            let farmName = recSummaries[0].farmName.trim();
            let customerName = recSummaries[0].customerName.trim();
            let fieldName = recSummaries[0].fieldName.trim();
            if (customerCount > 1) {
                customerName = `${customerCount}customers`;
                farmName = "";
                fieldName = "";
            }

            recFilenames = [
                {
                    farmName,
                    customerName,
                    fieldName,
                    files: [
                        {
                            nutrient: null,
                            productMixName: "",
                            filename: this.buildFilenameFromFormat(
                                customerName,
                                farmName,
                                fieldName,
                                "",
                                exportFormat,
                                "M_",
                                null
                            ),
                        },
                    ],
                },
            ];
        } else if (multiProductYn) {
            const byField = Object.values(uniqBy(recSummaries, "fieldGuid"));
            if (recDetails.length > 1) {
                zipFilename = "BatchExport";
            }
            zipFilename =
                byField.length > 1 ? "BatchExport" : `MultiProduct_${recSummaries[0].customerName}`;

            recFilenames = byField.map((summary) => {
                const customerName = summary.customerName.trim();
                const farmName = summary.farmName.trim();
                const fieldName = summary.fieldName.trim();
                return {
                    customerName,
                    farmName,
                    fieldName,
                    files: [
                        {
                            nutrient: null,
                            productMixName: null,
                            filename: this.buildFilenameFromFormat(
                                customerName,
                                farmName,
                                fieldName,
                                "",
                                exportFormat,
                                null,
                                null
                            ),
                        },
                    ],
                };
            });
        } else if (mergeControllerFilesYn) {
            zipFilename = `Merged_${recSummaries[0].customerName}_${recSummaries[0].farmName}`;

            const customerCount = new Set(recSummaries.map((x) => x.customerName)).size;
            let farmName = recSummaries[0].farmName.trim();
            let customerName = recSummaries[0].customerName.trim();
            let fieldName = recSummaries[0].fieldName.trim();
            if (customerCount > 1) {
                customerName = `${customerCount}customers`;
                farmName = "";
                fieldName = "";
            }

            const files = uniqBy(
                recDetails
                    .flatMap((detail) => detail.recAreaList.filter((area) => area.applyRecToArea))
                    .flatMap((area) => area.recs)
                    .flatMap((rec) => rec.recNutrientList)
                    .map((nutrient) => ({
                        nutrient: null,
                        productMixName: nutrient.recNutrientProductMix.name,
                        filename: this.buildFilenameFromFormat(
                            customerName,
                            farmName,
                            fieldName,
                            nutrient.recNutrientProductMix.name,
                            exportFormat,
                            "M_",
                            null
                        ),
                    })),
                "productMixName"
            );

            recFilenames = [
                {
                    customerName,
                    fieldName,
                    farmName,
                    files,
                },
            ];
        } else {
            zipFilename =
                recDetails.length === 1
                    ? `${recSummaries[0].customerName}_${recSummaries[0].fieldName}_${recSummaries[0].farmName}`
                    : "BatchExport";
            recFilenames = recDetails.map((rec, recIndex) => {
                const customerName = recSummaries[recIndex].customerName.trim();
                const farmName = recSummaries[recIndex].farmName.trim();
                const fieldName = recSummaries[recIndex].fieldName.trim();
                const files = rec.recAreaList
                    .filter((area) => area.applyRecToArea)
                    .flatMap((area) => area.recs)
                    .flatMap((rec) => rec.recNutrientList)
                    .map((nutrient) => ({
                        nutrient: nutrient.equationName.replace(" Rec", ""),
                        productMixName: nutrient.recNutrientProductMix.name,
                        filename: this.buildFilenameFromFormat(
                            customerName,
                            farmName,
                            fieldName,
                            nutrient.recNutrientProductMix.name,
                            exportFormat,
                            null,
                            null
                        ),
                    }));

                return {
                    customerName,
                    farmName,
                    fieldName,
                    customerGuid: recSummaries[recIndex].customerGuid,
                    fieldGuid: recSummaries[recIndex].fieldGuid,
                    recGeneralGuid: recSummaries[recIndex].recGeneralGuid,
                    files: uniqBy(files, "productMixName"),
                };
            });
        }

        this.setState({
            recFilenames,
            zipFilename: zipFilename.replaceAll(" ", "").replaceAll(/_{2,}/g, "_"),
            recNameStart: null,
            recNameEnd: null,
        });
    }

    private onChangeControllerType(
        newSelectedControllerType: string,
        multiProductYn = false,
        fileTypeGuid = false
    ) {
        const { selectedControllerType, selectedControllerFileType } = this.state;
        if (
            newSelectedControllerType === selectedControllerType &&
            selectedControllerFileType &&
            selectedControllerFileType.sendThroughOnsite
        ) {
            // if it's just a multiProductYn change and a filetype sent through onsite is already selected, don't reload the file type list
            this.setState({ isLoading: false });
            return;
        }

        const { recGeneralGuidList } = this.props;
        this.setState(
            {
                selectedControllerType: newSelectedControllerType,
                isLoading: true,
            },
            () => {
                FileImportAPI.getControllerFileTypeList(
                    multiProductYn ? recGeneralGuidList : [],
                    newSelectedControllerType
                ).then((result) => {
                    const controllerFileTypeOptions = result.map((r) => ({
                        label: r.name,
                        value: {
                            guid: r.guid,
                            sendThroughOnsite: r.sendThroughOnsite,
                        },
                    }));
                    let selectedControllerFileType = null;
                    if (controllerFileTypeOptions.length === 1) {
                        selectedControllerFileType = controllerFileTypeOptions[0].value;
                    } else if (fileTypeGuid) {
                        selectedControllerFileType = controllerFileTypeOptions.filter(
                            (o) => o.value.guid === fileTypeGuid
                        )[0]?.value;
                    }
                    this.setState({
                        controllerFileTypeOptions,
                        isLoading: false,
                        selectedControllerFileType,
                    });
                });
            }
        );
    }

    private onChangeControllerFormat(selectedControllerFileType) {
        let { multiProductYn } = this.state;
        if (selectedControllerFileType.sendThroughOnsite) {
            multiProductYn = false;
        }

        this.setState({ selectedControllerFileType, multiProductYn });
    }

    private validateControllerFileMerge() {
        const { recSummaries } = this.props;
        const fields = new Set([...recSummaries.map((r) => r.fieldGuid)]);
        const recTypes = recSummaries.map((r) => r.recType);
        const warnings: string[] = [];
        if (fields.size !== recSummaries.length) {
            warnings.push(messages.exportValidationWarningOneRecPerField);
        }

        if (
            recTypes.some((r) => r.includes("Planting")) &&
            recTypes.some((r) => r.includes("Application"))
        ) {
            warnings.push(messages.exportValidationWarningOneRecType);
        }

        if (
            recTypes.some((r) => r === "Planting (Manual)") &&
            recTypes.some((r) => r === "Planting (Equation)")
        ) {
            warnings.push(messages.exportValidationWarningPlantingRecType);
        }

        return warnings;
    }

    private validateFileNaming() {
        const { zipFilename, recNameEnd, recNameStart, recFilenames, erroredControls } = this.state;
        let showWarning = false;

        if (!zipFilename || zipFilename.replace(this.invalidFilenameChars, "") !== zipFilename) {
            showWarning = true;
            erroredControls.add("zipFilename");
        } else {
            erroredControls.delete("zipFilename");
        }

        if (recNameEnd && recNameEnd.replace(this.invalidFilenameChars, "") !== recNameEnd) {
            showWarning = true;
            erroredControls.add("recNameEnd");
        } else {
            erroredControls.delete("recNameEnd");
        }

        if (recNameStart && recNameStart.replace(this.invalidFilenameChars, "") !== recNameStart) {
            showWarning = true;
            erroredControls.add("recNameStart");
        } else {
            erroredControls.delete("recNameStart");
        }

        recFilenames.forEach((recNutrients, recIndex) =>
            recNutrients.files.forEach((nutrient, nutrientIndex) => {
                const controlLabel = `file-${recIndex}-${nutrientIndex}`;
                if (
                    !nutrient.filename ||
                    nutrient.filename.replace(this.invalidFilenameChars, "") !== nutrient.filename
                ) {
                    showWarning = true;
                    erroredControls.add(controlLabel);
                } else {
                    erroredControls.delete(controlLabel);
                }
            })
        );

        return showWarning ? [messages.exportValidationWarningFilenameInvalid] : [];
    }

    private buildFilenameFromFormat(
        customer: string,
        farm: string,
        field: string,
        product: string,
        exportFormat: string | null,
        prepend: string | null,
        append: string | null
    ): string {
        // this comes from the export controller in ai360.python
        return (
            (prepend ?? "") +
            (exportFormat
                ?.replace("Customer", customer)
                .replace("Farm", farm)
                .replace("Field", field)
                .replace("Product", product)
                .replaceAll(" - Mix", " Mix")
                .trim()
                .replaceAll(" ", "_")
                .replaceAll(this.invalidFilenameChars, "_")
                .replaceAll(/_{2,}/g, "_")
                .replaceAll(/^_|_$/gm, "") ?? "") +
            (append ?? "")
        );
    }

    private updateFileNaming() {
        const { selectedExportFormat, recNameStart, recNameEnd, productFormatOptions } = this.state;

        const formatDisplay = productFormatOptions.find(
            (x) => x.guid === selectedExportFormat
        )?.name;

        const recFilenames = cloneDeep(this.state.recFilenames);

        recFilenames.forEach((recFiles) =>
            recFiles.files.forEach((nutrient) => {
                nutrient.filename = this.buildFilenameFromFormat(
                    recFiles.customerName,
                    recFiles.farmName,
                    recFiles.fieldName,
                    nutrient.productMixName,
                    formatDisplay,
                    recNameStart,
                    recNameEnd
                );
            })
        );

        this.setState({ recFilenames });
    }

    private getValidationMessages() {
        const { validationWarnings } = this.state;
        const { formatMessage } = this.props.intl;

        return validationWarnings.map((warning) => {
            return (
                <div className="validation-message" key={warning.id}>
                    {formatMessage(warning)}
                </div>
            );
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (
            (nextProps.userGuid && this.props.userGuid !== nextProps.userGuid) ||
            (nextProps.isOpen && this.props.isOpen !== nextProps.isOpen)
        ) {
            this.setState({ isLoading: true }, () => this.fetchOptions(nextProps));
        }
    }

    render() {
        const { recGeneralGuidList, isOpen, onClose, recSummaries } = this.props;
        const { formatMessage } = this.props.intl;
        const {
            controllerFileTypeOptions,
            controllerTypeOptions,
            createLoadsheetYn,
            isLoading,
            mergeControllerFilesYn,
            multiProductYn,
            productFormatOptions,
            selectedControllerFileType,
            selectedControllerType,
            selectedExportFormat,
            validationWarnings,
            editFilenames,
            erroredControls,
        } = this.state;

        const isGenericType =
            controllerTypeOptions.find((x) => x.value === selectedControllerType)?.label ===
                "Generic" ?? false;

        const exportControllerFilesTitle = formatMessage(messages.exportControllerFile, {
            count: recGeneralGuidList.length,
        });
        const title =
            recGeneralGuidList.length > 1
                ? `${exportControllerFilesTitle} (${recGeneralGuidList.length})`
                : exportControllerFilesTitle;

        const sendThroughOnsite =
            (selectedControllerFileType && selectedControllerFileType.sendThroughOnsite) || false;

        const fileNameFormats = productFormatOptions.map((o) => {
            return { label: o.name, value: o.guid };
        });

        const exportFormat = fileNameFormats.find((o) => o.value === selectedExportFormat)
            ? selectedExportFormat
            : null;

        const canEditFilenames = !recSummaries.some(
            (x) => x.recType !== REC_TYPE_NAME_EQUATION_APPLICATION
        );

        const canExport =
            selectedControllerType != null &&
            selectedControllerFileType != null &&
            exportFormat != null;

        return (
            <DialogBox
                draggable
                action={formatMessage(messages.exportToFile)}
                actionDisabled={!canExport}
                className="export-controller-file-modal"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                isOpen={isOpen}
                onAction={() => this.onAction()}
                onClose={() => onClose()}
                title={title}
                unrestricted
            >
                <div className="row">
                    <SelectInput
                        clearable={false}
                        onChange={(value) => this.onChangeControllerType(value)}
                        options={controllerTypeOptions}
                        required
                        placeholderText="Controller Company"
                        value={selectedControllerType}
                    />
                    <SelectInput
                        clearable={false}
                        onChange={(value) => this.onChangeControllerFormat(value)}
                        options={controllerFileTypeOptions}
                        required
                        placeholderText="Controller Format"
                        value={selectedControllerFileType}
                    />
                </div>
                <div className="row">
                    <div className="checkboxes">
                        <Checkbox
                            label="Loadsheet"
                            onChange={(e, createLoadsheetYn) =>
                                this.setState({ createLoadsheetYn })
                            }
                            value={createLoadsheetYn}
                        />
                        <Checkbox
                            label="Multi-Product File"
                            onChange={(e, multiProductYn) => {
                                this.setState({ multiProductYn }, () => this.rebuildRecFiles());
                                this.onChangeControllerType(
                                    this.state.selectedControllerType,
                                    multiProductYn
                                );
                            }}
                            value={multiProductYn}
                        />
                        {!sendThroughOnsite && recGeneralGuidList.length > 1 && (
                            <Checkbox
                                label="Merge Files"
                                onChange={(e, mergeControllerFilesYn) =>
                                    this.setState(
                                        {
                                            mergeControllerFilesYn,
                                        },
                                        () => this.rebuildRecFiles()
                                    )
                                }
                                value={mergeControllerFilesYn}
                            />
                        )}
                        {canEditFilenames && (
                            <Checkbox
                                label="Edit File Names"
                                value={editFilenames}
                                onChange={(_, editFilenames) => this.setState({ editFilenames })}
                            />
                        )}
                    </div>
                    <SelectInput
                        clearable={false}
                        onChange={(value) =>
                            this.setState({ selectedExportFormat: value }, () =>
                                this.updateFileNaming()
                            )
                        }
                        options={fileNameFormats}
                        required={true}
                        placeholderText="File Name Format"
                        value={exportFormat}
                    />
                </div>
                {canEditFilenames && this.state.editFilenames && (
                    <React.Fragment>
                        <div className="row full-width">
                            <TextInput
                                placeholderText="Zip File Name"
                                value={this.state.zipFilename}
                                hasError={erroredControls.has("zipFilename")}
                                required={true}
                                onChange={(zipFilename) => this.setState({ zipFilename })}
                                onBlur={() =>
                                    this.setState({ zipFilename: this.state.zipFilename.trim() })
                                }
                            />
                        </div>
                        {isGenericType && this.renderMultiFileName()}
                    </React.Fragment>
                )}

                {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
                <DialogBox
                    title={formatMessage(messages.exportValidationWarningTitle, {
                        count: validationWarnings.length,
                    })}
                    isOpen={validationWarnings.length > 0}
                    onClose={() => this.setState({ validationWarnings: [] })}
                >
                    {<div>{this.getValidationMessages()}</div>}
                </DialogBox>
            </DialogBox>
        );
    }

    protected updateNutrientFilename(recIndex: number, nutrientIndex: number, filename: string) {
        const { recFilenames } = this.state;
        recFilenames[recIndex].files[nutrientIndex].filename = filename;

        this.setState({ recFilenames: recFilenames });
    }

    private renderMultiFileName() {
        const { recNameEnd, recNameStart, recFilenames, erroredControls, mergeControllerFilesYn } =
            this.state;

        const showPrePostfix =
            reduce(recFilenames, (prev, curr) => prev + curr.files.length, 0) > 1;
        const sectionClass =
            recFilenames[0]?.files[0]?.nutrient ?? false ? "file-list" : "file-list no-labels";

        return (
            <React.Fragment>
                {showPrePostfix && (
                    <div className="row two-column">
                        <TextInput
                            placeholderText="Start All Rec File Names With"
                            value={recNameStart}
                            hasError={erroredControls.has("recNameStart")}
                            onChange={(recNameStart) =>
                                this.setState({ recNameStart }, () => this.updateFileNaming())
                            }
                        />
                        <TextInput
                            placeholderText="End All Rec File Names With"
                            value={recNameEnd}
                            hasError={erroredControls.has("recNameEnd")}
                            onChange={(recNameEnd) =>
                                this.setState({ recNameEnd }, () => this.updateFileNaming())
                            }
                        />
                    </div>
                )}
                <div className={sectionClass}>
                    {recFilenames?.map((rec, recIndex) => (
                        <React.Fragment key={recIndex}>
                            {!mergeControllerFilesYn && recFilenames.length > 1 && (
                                <div className="field">
                                    {rec.customerName} {rec.farmName ? `> ${rec.farmName} ` : ""}
                                    &gt; {rec.fieldName.trim()}
                                </div>
                            )}
                            {recFilenames[recIndex].files.map((nutrient, nutrientIndex) => (
                                <React.Fragment key={`${recIndex}-${nutrientIndex}`}>
                                    {nutrient.nutrient && <label>{nutrient.nutrient}</label>}
                                    <TextInput
                                        placeholderText="Rec File Name"
                                        required
                                        hasError={erroredControls.has(
                                            `file-${recIndex}-${nutrientIndex}`
                                        )}
                                        value={nutrient.filename}
                                        onChange={(filename) =>
                                            this.updateNutrientFilename(
                                                recIndex,
                                                nutrientIndex,
                                                filename
                                            )
                                        }
                                        onBlur={() =>
                                            this.updateNutrientFilename(
                                                recIndex,
                                                nutrientIndex,
                                                nutrient.filename.trim()
                                            )
                                        }
                                    />
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

const ExportControllerFileModal = connect(mapStateToProps)(injectIntl(ExportControllerFileModal_));
export default ExportControllerFileModal;
