import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { withRouter } from "react-router-dom";

import { LocalStorageHelpers } from "@ai360/core";

import { messages } from "../i18n-messages";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { Button, Checkbox, TextInput } from "../../core";

const { LoginState } = actions;

export interface ILoginForm_Props {
    error?: any;
    history: any;
    intl: intlShape;
    onError: (error: Error) => void;
    onLogin: (username: string, password: string, history: any) => void;
    onSetError: (error: Error) => void;
    onSetLoginState: (action: number) => void;
    onSetUsers: (users: Record<string, any>) => void;
    onStartProcessing: () => void;
    onStopProcessing: () => void;
}

const LoginForm_ = (props: ILoginForm_Props): JSX.Element => {
    const {
        error,
        history,
        intl,
        onError,
        onLogin,
        onSetError,
        onSetLoginState,
        onStartProcessing,
        onStopProcessing,
    } = props;

    const { formatMessage } = intl;

    const [username, setUsername] = useState<string>(
        LocalStorageHelpers.get(LocalStorageHelpers.USERNAME) || ""
    );
    const [password, setPassword] = useState<string>("");
    const [rememberMe, setRememberMe] = useState<boolean>(
        Boolean(LocalStorageHelpers.get(LocalStorageHelpers.REMEMBER_ME))
    );
    const [txtPassword, setTxtPassword] = useState<Record<string, any>>(null);
    const setTxtUsername = useState<Record<string, any>>(null)[1];

    function _clearPassword() {
        setPassword("");
        if (txtPassword) {
            txtPassword.input.focus();
        }
    }

    function _handleError(error) {
        _clearPassword();
        onError(error);
        onStopProcessing();
    }

    function _handleKeyDown(event) {
        if (event.key === "Enter") {
            login();
        }
    }

    function login() {
        onStartProcessing();
        onSetError(null);
        if (rememberMe) {
            LocalStorageHelpers.set(LocalStorageHelpers.USERNAME, username);
            LocalStorageHelpers.set(LocalStorageHelpers.REMEMBER_ME);
        } else {
            LocalStorageHelpers.remove(LocalStorageHelpers.USERNAME);
            LocalStorageHelpers.remove(LocalStorageHelpers.REMEMBER_ME);
        }
        if (username.length === 0) {
            _handleError(formatMessage(messages.usernameRequired));
            return;
        } else if (password.length === 0) {
            _handleError(formatMessage(messages.passwordRequired));
            return;
        }

        onLogin(username, password, history);
    }

    function recoverPassword(evt) {
        evt.preventDefault();
        onSetError(null);
        onSetLoginState(LoginState.RECOVER_PASSWORD);
    }

    useEffect(() => {
        if (error) {
            _clearPassword();
            onStopProcessing();
        }
    }, [error]);

    const inputFormProps = {
        onKeyDown: (e) => _handleKeyDown(e),
    };
    return (
        <div>
            <div className="login-input-container">
                <TextInput
                    tabIndex={1}
                    value={username}
                    autoComplete={true}
                    placeholderText={formatMessage(messages.usernameLbl)}
                    {...inputFormProps}
                    //removing whitespaces as you type does not trigger re-render on state change
                    onChange={(username) => setUsername(username)}
                    //onBlur does not pass in the text input value, using the value stored by onChange
                    onBlur={() => setUsername(username.replaceAll(" ", ""))}
                    ref={(input) => {
                        setTxtUsername(input);
                    }}
                />
                <TextInput
                    tabIndex={2}
                    value={password}
                    password
                    placeholderText={formatMessage(messages.passwordLbl)}
                    {...inputFormProps}
                    onChange={(password) => setPassword(password)}
                    ref={(input) => {
                        setTxtPassword(input);
                    }}
                />
                <div className="login-options-div">
                    <div className="login-remember-me-container">
                        <Checkbox
                            tabIndex={3}
                            value={rememberMe}
                            label={formatMessage(messages.rememberMeLbl)}
                            {...inputFormProps}
                            onChange={(e, rememberMe) => setRememberMe(rememberMe)}
                        />
                    </div>
                    <div className="login-forgot-password">
                        <a
                            tabIndex={4}
                            href="#Recover Password"
                            onClick={(evt) => recoverPassword(evt)}
                        >
                            {formatMessage(messages.forgotPasswordLnk)}
                        </a>
                    </div>
                </div>
            </div>
            <div className="login-center">
                <Button
                    tabIndex={5}
                    className="login-btn"
                    type="login"
                    {...inputFormProps}
                    onClick={() => login()}
                />
            </div>
        </div>
    );
};

export const LoginForm = injectIntl(LoginForm_);

const mapStateToProps = (state) => {
    return {
        error: selectors.getError(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (email, password, history) => dispatch(actions.login(email, password, history)),
        onSetError: (error) => dispatch(actions.setError(error)),
        onSetLoginState: (loginState) => dispatch(actions.setLoginState(loginState)),
        onSetUsers: (theUsers) => dispatch(actions.setUsersInfo(theUsers)),
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
    };
};

export default connect<
    Partial<ILoginForm_Props>,
    Partial<ILoginForm_Props>,
    Partial<ILoginForm_Props>
>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LoginForm));
