import { defineMessages } from "react-intl";

export const messages = defineMessages({
    activateRec: {
        id: "recModule.contextMenu.activateRec",
        defaultMessage: "Activate Rec",
    },
    clear: {
        id: "recModule.contextMenu.clear",
        defaultMessage: "Clear",
    },
    confirmationText: {
        id: "recModule.varietyHybridConfirmation.confirmationText",
        defaultMessage:
            "You have 1 or more zones without a variety selected, are you sure you want to Save?",
    },
    confirmationTitle: {
        id: "recModule.varietyHybridConfirmation.confirmationTitle",
        defaultMessage: "Confirm Save",
    },
    convertRecToEvent: {
        id: "recModule.contextMenu.convertRecToEvent",
        defaultMessage: "Convert To Event",
    },
    copy: {
        id: "recModule.contextMenu.copy",
        defaultMessage: "Copy",
    },
    delete: {
        id: "recModule.contextMenu.delete",
        defaultMessage: "Delete",
    },
    deleteRecConfirmation: {
        id: "recModule.confirmationDialog.deleteRecConfirmation",
        defaultMessage: "Are you sure you want to delete the selected Rec?",
    },
    deleteMultipleRecsConfirmation: {
        id: "recModule.confirmationDialog.deleteMultipleRecsConfirmation",
        defaultMessage: "Are you sure you want to delete the selected Recs?",
    },
    exportControllerFile: {
        id: "recModule.contextMenu.exportControllerFile",
        defaultMessage: "Export Controller {count, plural, one {File} other {Files}}",
    },
    exportComplete: {
        id: "recModule.exportControllerFile.exportComplete",
        defaultMessage: "Export Complete",
    },
    exportFailed: {
        id: "recModule.exportControllerFile.exportFailed",
        defaultMessage: "Export Failed",
    },
    exportToFile: {
        id: "recModule.exportControllerFile.exportToFile",
        defaultMessage: "Export To File",
    },
    exportValidationWarningTitle: {
        id: "recModule.exportControllerFile.exportValidationWarningTitle",
        defaultMessage:
            "Controller File Merge Validation {count, plural, one {Error} other {Errors}}",
    },
    exportValidationWarningOneRecPerField: {
        id: "recModule.exportControllerFile.exportValidationWarningOneRecPerField",
        defaultMessage: "Only one Rec per Field can be selected",
    },
    exportValidationWarningOneRecType: {
        id: "recModule.exportControllerFile.exportValidationWarningOneRecType",
        defaultMessage:
            "Application and Planting Recs cannot be combined in the same Controller File Merge",
    },
    exportValidationWarningPlantingRecType: {
        id: "recModule.exportControllerFile.exportValidationWarningPlantingRecType",
        defaultMessage:
            "Planting (Equation) and Planting (Manual) Recs cannot be combined in the same Controller File Merge",
    },
    exportValidationWarningFilenameInvalid: {
        id: "recModule.exportControllerFile.exportValidationWarningFilenameInvalid",
        defaultMessage:
            "File names required and cannot contain any of the following: !@#$%^&*()[]'{};:,/<>?|`~=+''",
    },
    viewEditDetails: {
        id: "recModule.contextMenu.viewEditDetails",
        defaultMessage: "View / Edit Details",
    },
});
